import React from "react"
import clsx from "clsx"

export default function NavbarBurger({ show, isMenuOpen, toggleMenu }) {
  if (!show) return null

  return (
    <button onClick={() => toggleMenu(state => !state)}>
      <div className={clsx("nav-menu-icon", { "menu-open": isMenuOpen })}>
        <div></div>
      </div>
    </button>
  )
}
