import { useEffect, useState } from "react"

export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState(getSize)

  useEffect(() => {
    const setSize = () => setScreenSize(getSize)
    setSize()
    window.addEventListener("resize", setSize)
    return () => window.removeEventListener("resize", setSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getSize() {
    const isClient = typeof window === "object"
    if (!isClient || window.innerWidth > 768) return "lg"
    return window.innerWidth > 480 ? "md" : "sm"
  }

  return screenSize
}
