import React from "react"

import NavbarLogo from "./navbar-logo"
import NavbarMenu from "./navbar-menu"

import "./navbar.scss"

const Navbar = () => (
  <nav>
    <NavbarLogo />
    <NavbarMenu />
  </nav>
)

export default Navbar
