import React from "react"

import { animated, useSpring } from "react-spring"

export default function NavbarDivider({ show, isMenuOpen }) {
  const spring = useSpring({ opacity: isMenuOpen ? 1 : 0 })

  if (!show) return null

  return <animated.span className="nav-divider" style={spring}></animated.span>
}
