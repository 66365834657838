import React, { useState, useEffect } from "react"

import NavbarBurger from "./navbar-burger"
import NavbarDivider from "./navbar-divider"
import NavbarLinks from "./navbar-links"

import useScreenSize from "../../../hooks/useScreenSize"

export default function NavbarMenu() {
  const screenSize = useScreenSize()

  const [isMenuOpen, toggleMenu] = useState(false)

  const show = screenSize !== "lg"

  useEffect(() => {
    toggleMenu(false)
  }, [show])

  return (
    <>
      <NavbarBurger
        show={show}
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
      />
      <NavbarDivider show={show} isMenuOpen={isMenuOpen} />
      <NavbarLinks show={show} isMenuOpen={isMenuOpen} />
    </>
  )
}
