import React from "react"
import clsx from "clsx"
import Navbar from "./navbar/navbar"
import Footer from "./footer/footer"
import "../../utils/icons"
import "normalize.css"
import "./layout.scss"

const Layout = ({ children, className }) => (
  <>
    <Navbar />
    <div className={clsx("layout-main", className)}>{children}</div>
    <Footer />
  </>
)

export default Layout
