import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function NavbarLogo() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="nav-container">
      <Link to="/" className="nav-logo">
        <Img fixed={data.file.childImageSharp.fixed} alt="groove-g logo" />
        <div>
          <p>
            Groove <span>G</span>
          </p>
          <hr />
          <p>Media Brand</p>
        </div>
      </Link>
    </div>
  )
}
