import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import { animated, useSprings } from "react-spring"
import { useInView } from "react-intersection-observer"

const links = [
  { to: "/about", text: "About Us" },
  { to: "/services", text: "Services" },
  { to: "/contact", text: "Contact Us" },
]

export default function NavbarLinks({ show, isMenuOpen }) {
  const [ref, inView] = useInView({ rootMargin: "-142px" })

  const showLinks = inView || !show

  const springs = useSprings(
    links.length,
    links.map((_, i) => ({
      opacity: showLinks ? 1 : 0,
      transform: `translateX(${showLinks ? 0 : -30}px)`,
      delay: !showLinks ? 0 : (links.length - i) * 125,
      immediate: !showLinks,
    }))
  )

  const cx = clsx("nav-links", {
    "menu-open": isMenuOpen,
    "not-mobile-menu": !show,
  })
  return (
    <ul ref={ref} className={cx}>
      {springs.map((props, i) => {
        const { to, text } = links[i]
        return (
          <li key={to}>
            <animated.div style={props}>
              <Link to={to}>{text}</Link>
            </animated.div>
          </li>
        )
      })}
    </ul>
  )
}
