import React from "react"
import { Link } from "gatsby"
import "./footer.scss"

const Footer = () => (
  <footer>
    <div>
      <Link to="/about">About Us</Link>
      <Link to="/services">Services</Link>
      <Link to="/contact">Contact Us</Link>
      <hr />
    </div>
    <p>&copy; {new Date().getFullYear()} Groove G MediaBrand Co.</p>
  </footer>
)

export default Footer
